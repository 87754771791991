import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa'
import { fetchUser, loginUser, fetchSocials } from './reducers/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { joinClassNames } from './utils'

function MatchTokens() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()

  const { matchPlatform } = params

  const user = useSelector((state) => state.user.user)
  const userFetched = useSelector((state) => state.user.userFetched)
  const loginError = useSelector((state) => state.user.loginError)

  const socials = useSelector((state) => state.user.socials)

  useEffect(() => {
    // dispatch(fetchUser())
    dispatch(fetchSocials())
  }, [])

  useEffect(() => {
    if (userFetched && _.isEmpty(user.influencer_id)) {
      navigate('/contact-us')
    }
  }, [userFetched, user.influencer_id])

  const socialPlatforms = [
    { name: 'Instagram', icon: <FaInstagram />, key: 'instagram-business' },
    { name: 'TikTok', icon: <FaTiktok />, key: 'tiktok' },
    { name: 'YouTube', icon: <FaYoutube />, key: 'youtube' },
  ]

  const icons = {
    instagram: <FaInstagram />,
    tiktok: <FaTiktok />,
    youtube: <FaYoutube />,
  }

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              <h4 className="card-title text-center mb-4 fw-bold">{user ? user.fullname : ''}</h4>
              {socials.map((social) => {
                if (Object.keys(icons).indexOf(social.platform) === -1) {
                  return null
                }
                return (
                  <div key={social.id} className="mb-3">
                    <div className="row g-0">
                      <div className="col">
                        {icons[social.platform]} {social.platform_account_name}
                      </div>
                      <div className="col"></div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MatchTokens
