import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { siteHeaderLogoUrl } from './config'
import { UserContext } from './UserContext'
import { logoutUser, resetUser } from './reducers/userSlice'

function Navbar() {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)

  const { auth_user_id, influencer_id, team_id } = user

  let link = `https://app.talentpitchpro.com/public/profile/${influencer_id}`

  if (team_id) link = link + `/team/${team_id}`

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-3 d-flex justify-content-center justify-content-md-start">
            <Link className="navbar-brand mx-auto mx-lg-0" to="/">
              <img
                style={{
                  maxWidth: '100%',
                }}
                src={siteHeaderLogoUrl}
                alt="Talent Pitch Pro Logo"
              />
            </Link>
          </div>
          <div className="col-12 col-md-7 d-flex justify-content-end align-items-center">
            {user ? (
              <div className="d-flex align-items-center">
                <span className="navbar-text mx-auto mx-lg-0 fw-bold">
                  <a href={link} target="_blank">
                    {user.fullname}
                  </a>
                </span>
                {auth_user_id && (
                  <button
                    className="btn btn-link text-decoration-none"
                    onClick={() => {
                      dispatch(logoutUser())
                      dispatch(resetUser())
                      window.location.href = '/'
                    }}
                  >
                    Logout
                  </button>
                )}
              </div>
            ) : (
              <>
                ...
                {/* <Link className="nav-link" to="/login">
                  Login / Register
                </Link> */}
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
