import { useState, useEffect, useCallback } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import PlatformConnectMenu from './components/PlatformConnectMenu'
import _ from 'lodash'
import { fetchSocials, fetchTokens } from './reducers/userSlice'
import { getSocialAccountName } from './utils'
import { Modal, Button } from 'react-bootstrap' // Add this import

function PlatformConnect({}) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSocials())
    dispatch(fetchTokens())
  }, [])

  const user = useSelector((state) => state.user)

  const socials = useSelector((state) => state.user.socials)
  const tokens = useSelector((state) => state.user.tokens)
  const params = useParams()

  const [showModal, setShowModal] = useState(false) // Add this state

  const { platform } = params

  const handleConnect = (platform) => {
    window.location.href = `/api/auth/${platform}-login`
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleDisconnect = async (pendingSocial) => {
    try {
      await axios.post('/api/disconnect', {
        social_account_id: pendingSocial.id,
        platform: pendingSocial.platform,
      })
      handleCloseModal()
      dispatch(fetchTokens())
    } catch (error) {
      console.error('Error disconnecting the account:', error)
    }
  }

  // Add these modal handling functions
  const handleShowModal = (accountId) => {
    setShowModal(true)
  }

  const pending_social_id = localStorage.getItem('pending_social_id')

  const pendingSocial = pending_social_id
    ? socials.find((social) => social.id === pending_social_id)
    : null

  let socialPlatforms = [
    { name: 'Instagram', icon: <FaInstagram />, key: 'instagram' },
    { name: 'TikTok', icon: <FaTiktok />, key: 'tiktok' },
    { name: 'YouTube', icon: <FaYoutube />, key: 'youtube' },
  ]

  socialPlatforms = socialPlatforms.filter((item) => item.key === platform)

  const selectedPlatform = _.find(socialPlatforms, { key: platform })

  if (!user.userFetched) {
    return 'Loading...'
  }

  const selectedPlatformHasToken = useCallback(() => {
    if (!pendingSocial || tokens.length === 0) {
      return false
    }

    return tokens.some((token) => token.social_account_id === pendingSocial.id)
  }, [tokens, pendingSocial])()

  // if there is no pending social ID, we need to send them back home

  if (user.influencer_id && !pending_social_id) {
    return (
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card bg-white">
              <div className="card-body">
                <div className="text-center">
                  <h2 className="mb-3">No Social Account Selected</h2>
                  <p>
                    Sorry! Something went wrong identifying the account to connect. Go back to the
                    home page and try again.
                  </p>
                  <p>
                    <Link to="/">Home</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              <div className="text-center">
                <h2 className="mb-3">
                  <span className="me-2">{selectedPlatform.icon}</span>
                  {selectedPlatform.name}
                </h2>
                {selectedPlatformHasToken ? (
                  <>
                    <p>
                      You connected your account, {getSocialAccountName(pendingSocial)}. Thanks!
                    </p>
                    <p>
                      <button
                        onClick={() => handleShowModal()}
                        className="btn btn-danger text-white"
                      >
                        Disconnect
                      </button>
                    </p>
                    <p>
                      Problem? <a href="mailto:erick@talentpitchpro.com">Contact us</a>.
                    </p>
                    <p>
                      <Link to="/">Home</Link>
                    </p>
                  </>
                ) : (
                  <>
                    {pendingSocial && <p>Connect {getSocialAccountName(pendingSocial)}</p>}
                    <PlatformConnectMenu
                      socialPlatforms={socialPlatforms}
                      handleConnect={handleConnect}
                    />
                    <p className="mt-3">
                      Note: You will be directed away from our site to connect. To avoid
                      accidentally connecting the wrong account, please ensure you are signed in to
                      the account you want to verify prior to starting the authentication process.
                    </p>
                    {pendingSocial?.platform === 'instagram' && (
                      <>
                        <p>
                          For Instagram, your account must be set to a "professional" account
                          designated as a business and should be connected to a Facebook Page,
                          which you will confirm when you return to our site. If you do not have a
                          Facebook Page connected to your Instagram account, we will not be able to
                          see your Instagram posts or statistics.
                        </p>
                        <p>
                          Read about connecting your Instagram account to a Facebook Page on{' '}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://help.instagram.com/570895513091465?helpref=faq_content"
                          >
                            Instagram's help page
                          </a>
                          .
                        </p>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* Confirmation Modal */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Disconnection</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Please confirm that you want to disconnect the account. You will be able to
              re-authorize later if desired.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={() => handleDisconnect(pendingSocial)}>
                Disconnect
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default PlatformConnect
