import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa'
import { Modal, Button } from 'react-bootstrap'

function AddPlatformModal({ show, onHide }) {
  const navigate = useNavigate()

  const platforms = [
    { name: 'Instagram', icon: <FaInstagram />, key: 'instagram' },
    { name: 'TikTok', icon: <FaTiktok />, key: 'tiktok' },
    { name: 'YouTube', icon: <FaYoutube />, key: 'youtube' },
  ]

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Platform</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-grid gap-2">
          {platforms.map((platform) => (
            <Button
              key={platform.key}
              variant="outline-primary"
              size="lg"
              className="d-flex align-items-center justify-content-center"
              onClick={() => {
                navigate(`/connect/${platform.key}`)
                onHide()
              }}
            >
              {platform.icon}
              <span className="ms-2">{platform.name}</span>
            </Button>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddPlatformModal
