import { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import millify from 'millify'
import fallbackImage from '/fallback.png'

function TikTokInfo() {
  const [userResponse, setUserResponse] = useState({})

  const pending_social_id = localStorage.getItem('pending_social_id')

  useEffect(() => {
    axios
      .post('/api/tiktok/confirm-info', {
        social_account_id: pending_social_id,
      })
      .then((response) => {
        setUserResponse(response.data)
      })
  }, [])

  const { user } = userResponse || {}

  const follower_count = user?.follower_count
  const video_count = user?.video_count

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              <>
                <p
                  style={{
                    fontSize: '1.5rem',
                  }}
                  className="card-title text-center mb-4 fw-bold"
                >
                  Confirm Your TikTok Information
                </p>
                <p>
                  Below you will see the information we received from TikTok for the account you
                  connected.
                </p>

                <hr />
                {userResponse === null && (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
                {user && (
                  <>
                    <div className="row justify-content-center">
                      <div className="col-3">
                        <img
                          src={user?.avatar_url || fallbackImage}
                          alt="avatar"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div className="col-5">
                        <p className="fw-bold">{user?.display_name}</p>
                        <p className="text-muted">@{user?.username}</p>
                        <p className="text-muted">{millify(follower_count)} followers</p>
                        <p className="text-muted">{millify(video_count)} videos</p>
                      </div>
                    </div>
                  </>
                )}

                <p className='mt-3'>
                  If there is a problem or you intended to connect a different account, please make sure you are signed
                  into the correct TikTok account in your browser, then go back to our Home page
                  and try again.
                </p>
                <p>
                  If everything looks good, just click the link below to return to the main page.
                </p>

                <p className="text-center mt-3">
                  <Link to="/">Home</Link>
                </p>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TikTokInfo
