import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa'
import { fetchUser, loginUser, fetchSocials, fetchTokens } from './reducers/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { joinClassNames } from './utils'
import { socialPlatforms } from './constants'


function ReviewAuthorizations() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()

  const { matchPlatform } = params

  const user = useSelector((state) => state.user.user)
  const userFetched = useSelector((state) => state.user.userFetched)
  const loginError = useSelector((state) => state.user.loginError)

  const socials = useSelector((state) => state.user.socials)
  const tokens = useSelector((state) => state.user.tokens)

  useEffect(() => {
    dispatch(fetchTokens())
  }, [])

  useEffect(() => {
    if (userFetched && _.isEmpty(user.influencer_id)) {
      navigate('/contact-us')
    }
  }, [userFetched, user.influencer_id])


  const icons = {
    instagram: <FaInstagram />,
    tiktok: <FaTiktok />,
    youtube: <FaYoutube />,
  }

  console.log(tokens)

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              <h4 className="card-title text-center mb-4 fw-bold">Review Authorizations</h4>
              {socialPlatforms.map((platform) => {
                console.log(platform)
                const platformTokens = tokens.filter((token) => token.platform === platform.key)

                return (
                  <div key={platform.key} className="mb-3">
                    <p>
                      {platform.icon} {platform.name}
                    </p>
                    {platformTokens.length > 0 ? (
                      <ul>
                        {platformTokens.map((token) => {
                          return (
                            <li key={token.id}>
                              <p>{token.access_token}</p>
                            </li>
                          )
                        })}
                      </ul>
                    ) : (
                      <p>No authorizations for {platform.name}</p>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewAuthorizations
