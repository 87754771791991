import { useState, useEffect } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import axios from 'axios'

function TikTokRedirect() {
  const location = useLocation()
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get('code')
    const scopes = queryParams.get('scopes')
    const state = queryParams.get('state')
    const errorParam = queryParams.get('error')
    const errorDescription = queryParams.get('error_description')

    const pending_social_id = localStorage.getItem('pending_social_id')

    const handleTikTokRedirect = async () => {
      if (errorParam) {
        console.error('TikTok authorization error:', errorParam, errorDescription)
        setError(errorDescription || 'An error occurred during TikTok authorization')
        setLoading(false)
        return
      }

      if (!code) {
        setError('No authorization code received from TikTok')
        setLoading(false)
        return
      }

      try {
        const response = await axios.post('/api/auth/tiktok/callback', {
          code,
          scopes,
          state,
          social_account_id: pending_social_id,
        })

        if (response.data.success) {
          navigate('/tiktok/info')
        } else {
          throw new Error(response.data.error || 'Failed to exchange code for access token')
        }
      } catch (err) {
        console.error('Error processing TikTok callback:', err)
        setError(err.message || 'An error occurred while processing your request')
      } finally {
        setLoading(false)
      }
    }

    handleTikTokRedirect()
  }, [location.search, navigate])

  if (loading) {
    return (
      <div className="container mt-5 text-center">
        <h2>Processing TikTok Authorization</h2>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="container mt-5 text-center">
        <h2>TikTok Authorization Error</h2>
        <p className="text-danger">{error}</p>
        <p>
          Please <Link to="/">try again</Link> or contact support for assistance.
        </p>
      </div>
    )
  }

  return null
}

export default TikTokRedirect
