import { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { FaInstagram, FaTiktok, FaYoutube, FaPlus } from 'react-icons/fa'
import { fetchUser, loginUser, fetchSocials, fetchTokens } from './reducers/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { joinClassNames } from './utils'
import AddPlatformModal from './components/AddPlatformModal'

function StartPage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user.user)
  const userFetched = useSelector((state) => state.user.userFetched)
  const loginError = useSelector((state) => state.user.loginError)
  const haveTokens = useSelector((state) => state.user.haveTokens)

  const tokens = useSelector((state) => state.user.tokens)

  useEffect(() => {
    dispatch(fetchUser())
    dispatch(fetchTokens())
  }, [])

  // useEffect(() => {
  //   if (userFetched && _.isEmpty(user.influencer_id)) {
  //     navigate('/contact-us')
  //   }
  // }, [userFetched, user.influencer_id])

  const [showModal, setShowModal] = useState(false)

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              <h4 className="card-title text-center mb-4 fw-bold">{user ? user.fullname : ''}</h4>

              {user.authed ? (
                <div className="text-center">
                  <h2 className="mb-3">Your Connected Accounts</h2>
                  
                  <div className="mb-4">
                    {Object.entries(tokens).map(([platform, token]) => (
                      token && (
                        <div key={platform} className="mb-2">
                          <div className="d-flex align-items-center justify-content-between bg-light p-3 rounded">
                            <div className="d-flex align-items-center">
                              {platform === 'instagram' && <FaInstagram className="me-2" />}
                              {platform === 'tiktok' && <FaTiktok className="me-2" />}
                              {platform === 'youtube' && <FaYoutube className="me-2" />}
                              <span className="text-capitalize">{platform}</span>
                            </div>
                            <span className="text-muted">Connected</span>
                          </div>
                        </div>
                      )
                    ))}
                  </div>

                  <button 
                    className="btn btn-primary btn-lg w-100 d-flex align-items-center justify-content-center"
                    onClick={() => setShowModal(true)}
                  >
                    <FaPlus className="me-2" />
                    Add New Platform
                  </button>

                  {/* <p className="mt-4">
                    <Link to="/review-authorizations">Review Authorizations</Link>
                  </p>
 */}
                  <AddPlatformModal 
                    show={showModal}
                    onHide={() => setShowModal(false)}
                  />
                </div>
              ) : (
                <div className="text-center">
                  {loginError ? (
                    <div className="alert alert-danger" role="alert">
                      {loginError}
                    </div>
                  ) : (
                    <>
                      <h2>Waiting for login...</h2>
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartPage
