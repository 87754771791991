import { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa'
import { fetchUser, loginUser, fetchSocials, fetchTokens } from './reducers/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { joinClassNames } from './utils'
import { Modal, Button } from 'react-bootstrap'

const getSocialAccountName = (social) => {
  if (social.platform === 'youtube') return social.platform_account_nickname

  return social.platform_account_name
}

const ContactInfoModal = ({ show, onHide, onSubmit }) => {
  const user = useSelector((state) => state.user.user)

  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  useEffect(() => {
    if (user.contact) {
      setEmail(user.contact.email || '')
      setPhone(user.contact.phone || '')
    }
  }, [user.contact])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (email === '' && phone === '') {
      return
    }

    onSubmit({ email, phone })
    onHide()
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Thank you for connecting!</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">
              Phone
            </label>
            <input
              type="tel"
              className="form-control"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="text-end mb-3">
            <button className="custom-confirm-button" type="submit">
              Save
            </button>
          </div>
        </form>
        <p>
          To ensure you are aware of how your data is used, social media platforms ask us from time
          to time to re-authorize your account. Please provide a phone number or email so that we
          have a way to get in touch.
        </p>
        <p>
          This contact information will be used ONLY for specific communication regarding your
          account, and will NOT be shared with third parties, used to send you promotions, or for
          anything else.
        </p>
      </Modal.Body>
    </Modal>
  )
}

function SocialStart() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user.user)
  const userFetched = useSelector((state) => state.user.userFetched)
  const loginError = useSelector((state) => state.user.loginError)
  const socials = useSelector((state) => state.user.socials)
  const tokens = useSelector((state) => state.user.tokens)

  const hasContactInfo = user.contact?.email || user.contact?.phone

  // Add these state variables
  const [showContactModal, setShowContactModal] = useState(false)
  const [userClosedModal, setUserClosedModal] = useState(
    localStorage.getItem('userClosedModal') || false,
  )

  // Add this effect to show the modal when appropriate
  useEffect(() => {
    if (tokens.length > 0 && !hasContactInfo && !userClosedModal && userFetched) {
      setShowContactModal(true)
    }
  }, [tokens, hasContactInfo, userClosedModal, userFetched])

  // Add this handler
  const handleContactInfoSubmit = (contactInfo) => {
    updateContactInfo(contactInfo)
    setShowContactModal(false)
  }

  const updateContactInfo = ({ email, phone }) => {
    axios
      .post('/api/update-contact-info', {
        email,
        phone,
      })
      .then(() => {
        dispatch(fetchUser())
      })
      .catch((error) => {
        console.error('Error updating contact info:', error)
      })
  }

  useEffect(() => {
    dispatch(fetchUser())
    dispatch(fetchSocials())
    dispatch(fetchTokens())
  }, [])

  useEffect(() => {
    // delete pending_social_id from localStorage when page loads
    localStorage.removeItem('pending_social_id')
  }, [])

  useEffect(() => {
    if (!userFetched) return
    const isKnownInfluencer = user?.influencer_id

    if (isKnownInfluencer) return

    // is signed in? go to manage-socials page

    if (user?.auth_user_id) {
      navigate('/manage-socials')
    } else {
      navigate('/contact-us')
    }
  }, [userFetched, user.influencer_id, user.auth_user_id])

  const socialPlatforms = [
    { name: 'Instagram', icon: (size) => <FaInstagram size={size} />, key: 'instagram' },
    { name: 'TikTok', icon: (size) => <FaTiktok size={size} />, key: 'tiktok' },
    { name: 'YouTube', icon: (size) => <FaYoutube size={size} />, key: 'youtube' },
  ]

  return (
    <div className="container">
      <div className="row mt-2">
        <div className="col-9">
          <p
            style={{
              lineHeight: '1.2',
            }}
            className="fs-2 fw-bold"
          >
            Enhance Your Brand Sales Profile
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-11">
          <p
            style={{
              fontSize: '.85rem',
              color: '#6c757d',
            }}
          >
            We're trusted partners of your management agency, working together to showcase your
            brand and help your manager pitch and secure valuable opportunities. Safely connect
            your social accounts to strengthen your brand profile with real-time verified
            data-driven insights.
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card bg-white">
            <div className="card-body">
              {user.authed ? (
                <div className="text-center">
                  <div className="mb-3 d-flex flex-row justify-content-center align-items-center">
                    {user.agency_logo_url && (
                      <div className={`px-2 ${user.agency_logo_dark ? 'bg-gray-800' : ''}`}>
                        <img
                          src={user.agency_logo_url}
                          style={{ width: '100px', height: 'auto', borderRadius: '15%' }}
                        />
                      </div>
                    )}
                    <div className="px-2">
                      <img
                        src={user.profile_picture_url}
                        style={{ width: '100px', height: 'auto', borderRadius: '15%' }}
                      />
                    </div>
                  </div>
                  <div className="mb-4 px-4">
                    <div className="progress" style={{ height: '10px' }}>
                      <div
                        className={`
                          progress-bar 
                          ${tokens.length === socials.length ? 'bg-success' : ''}
                          ${tokens.length > 0 && tokens.length < socials.length ? 'bg-warning' : ''}
                          ${tokens.length === 0 ? 'bg-secondary' : ''}
                        `}
                        role="progressbar"
                        style={{ 
                          width: `${(tokens.length / socials.length) * 100}%`,
                          transition: 'width 0.3s ease'
                        }}
                        aria-valuenow={tokens.length}
                        aria-valuemin="0"
                        aria-valuemax={socials.length}
                      ></div>
                    </div>
                    <div className="text-muted text-end mt-1" style={{ fontSize: '0.8rem' }}>
                      {tokens.length} of {socials.length} accounts connected
                    </div>
                  </div>
                  {/* <h3 className="mb-3">Connect Your Accounts</h3> */}
                  <ul className="list-group">
                    {socials.map((social, i) => {
                      const platform = socialPlatforms.find((p) => p.key === social.platform)

                      if (!platform) {
                        return null
                      }

                      const connectedSocial = _.find(tokens, { social_account_id: social.id })

                      return (
                        <li key={`social-${i}`} className="list-group-item border-0 my-2">
                          <div className="row justify-content-center">
                            <div className="col-2 col-md-2 text-center align-content-center">
                              {/* {social.picture_main_url && (
                                <img
                                  style={{ width: '100%', borderRadius: '15%' }}
                                  src={social.picture_main_url}
                                />
                              )} */}
                              {platform.icon(30)}
                            </div>
                            <div className="col-10 col-md-6 align-content-center d-flex">
                              <button
                                style={{ height: '100%' }}
                                className={joinClassNames([
                                  `btn`,
                                  'py-2',
                                  connectedSocial
                                    ? 'btn-primary text-white'
                                    : `btn-outline-primary`,
                                  `w-100 d-flex align-items-center justify-content-center`,
                                ])}
                                onClick={() => {
                                  localStorage.setItem('pending_social_id', social.id)
                                  navigate(`/connect/${platform.key}`)
                                }}
                              >
                                <div>
                                  <span className="ms-2">
                                    {connectedSocial
                                      ? `${getSocialAccountName(social)} Connected`
                                      : `Connect ${getSocialAccountName(social)}`}
                                  </span>
                                </div>
                              </button>{' '}
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                  {tokens.length >= 1 && (
                    <div className="row mt-3">
                      <div className="col-md-8 mx-auto text-center">
                        <Button
                          variant="outline-primary"
                          onClick={() => setShowContactModal(true)}
                        >
                          Update Contact Information
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-center">
                  {loginError ? (
                    <div className="alert alert-danger" role="alert">
                      {loginError}
                    </div>
                  ) : (
                    <>
                      <h2>Waiting for login...</h2>
                      <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ContactInfoModal
        show={showContactModal}
        onHide={() => {
          setShowContactModal(false)
          setUserClosedModal(true)
          localStorage.setItem('userClosedModal', true)
        }}
        onSubmit={handleContactInfoSubmit}
      />
    </div>
  )
}

export default SocialStart
